// images
import blogImg1 from "../images/resource/news-1.jpg";
import blogImg2 from "../images/resource/news-2.jpg";
import blogImg3 from "../images/resource/news-3.jpg";

import blogSingleImg1 from "../images/resource/news-7.jpg";
import blogSingleImg2 from "../images/resource/news-8.jpg";
import blogSingleImg3 from "../images/resource/news-9.jpg";



const blogs = [
    {
        id: '1',
        title: 'Google now disregards or overlooks all reciprocal links',
        slug:'Google-now-disregards-or-overlooks-all-reciprocal-links',
        screens: blogImg1,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Pablo Villalpando',
        authorTitle:'Criminal Lawyer',
        create_at: '14 AUG,23',
        blogSingleImg:blogSingleImg1, 
        comment:'35',
        blClass:'format-standard-image',
    },
    {
        id: '2',
        title: 'Absolute Links vs. Relative Links – SEO Intrinsic Value',
        slug:'Absolute-Links-vs-Relative-Links-SEO-Intrinsic-Value',
        screens: blogImg2,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Konal Biry',
        authorTitle:'Family Lawyer',
        create_at: '16 AUG,23',
        blogSingleImg:blogSingleImg2, 
        comment:'80',
        blClass:'format-video',
    },
    {
        id: '3',
        title: 'How to increase your ROI through scientific SEM?',
        slug:'How-to-increase-your-ROI-through-scientific-SEM',
        screens: blogImg3,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Jenefer Willy',
        authorTitle:'Business Lawyer',
        create_at: '18 AUG,23',
        blogSingleImg:blogSingleImg3,
        comment:'95',
        blClass:'format-standard-image',
    },
    {
        id: '4',
        title: 'Google now disregards or overlooks all reciprocal links',
        slug:'Google-now-disregards-or-overlooks-all-reciprocal-links',
        screens: blogImg1,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Pablo Villalpando',
        authorTitle:'Criminal Lawyer',
        create_at: '14 AUG,23',
        blogSingleImg:blogSingleImg1, 
        comment:'35',
        blClass:'format-standard-image',
    },
    {
        id: '5',
        title: 'Absolute Links vs. Relative Links – SEO Intrinsic Value',
        slug:'Absolute-Links-vs-Relative-Links-SEO-Intrinsic-Value',
        screens: blogImg2,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Konal Biry',
        authorTitle:'Family Lawyer',
        create_at: '16 AUG,23',
        blogSingleImg:blogSingleImg2, 
        comment:'80',
        blClass:'format-standard-image',
    },
    {
        id: '6',
        title: 'How to increase your ROI through scientific SEM?',
        slug:'How-to-increase-your-ROI-through-scientific-SEM',
        screens: blogImg3,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Jenefer Willy',
        authorTitle:'Business Lawyer',
        create_at: '18 AUG,23',
        blogSingleImg:blogSingleImg3,
        comment:'95',
        blClass:'format-video',
    },
    {
        id: '7',
        title: 'Google now disregards or overlooks all reciprocal links',
        slug:'Google-now-disregards-or-overlooks-all-reciprocal-links',
        screens: blogImg1,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Pablo Villalpando',
        authorTitle:'Criminal Lawyer',
        create_at: '14 AUG,23',
        blogSingleImg:blogSingleImg1, 
        comment:'35',
        blClass:'format-standard-image',
    },
    {
        id: '8',
        title: 'Absolute Links vs. Relative Links – SEO Intrinsic Value',
        slug:'Absolute-Links-vs-Relative-Links-SEO-Intrinsic-Value',
        screens: blogImg2,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Konal Biry',
        authorTitle:'Family Lawyer',
        create_at: '16 AUG,23',
        blogSingleImg:blogSingleImg2, 
        comment:'80',
        blClass:'format-standard-image',
    },
    {
        id: '9',
        title: 'How to increase your ROI through scientific SEM?',
        slug:'How-to-increase-your-ROI-through-scientific-SEM',
        screens: blogImg3,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Jenefer Willy',
        authorTitle:'Business Lawyer',
        create_at: '18 AUG,23',
        blogSingleImg:blogSingleImg3,
        comment:'95',
        blClass:'format-video',
    },
    {
        id: '10',
        title: 'Google now disregards or overlooks all reciprocal links',
        slug:'Google-now-disregards-or-overlooks-all-reciprocal-links',
        screens: blogImg1,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Pablo Villalpando',
        authorTitle:'Criminal Lawyer',
        create_at: '14 AUG,23',
        blogSingleImg:blogSingleImg1, 
        comment:'35',
        blClass:'format-standard-image',
    },
];

export default blogs;